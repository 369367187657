import { FaRegCircle, FaRegCircleCheck } from 'react-icons/fa6';

import CardModel from '../models/component_models/CardModel';
import StatusEffectModel from '../models/data_models/StatusEffectModel';

export default class StatusEffectUtils {
    static createCardData(dictItem, { select, selectedIds } = {}) {
        const obj = new StatusEffectModel(dictItem);
        return new CardModel({
            id: obj.id,
            sections: [
                'descriptionKey',
                'statusEffectType',
                'value',
                'expiration',
            ].map((key) => ({
                type: 'value',
                title: obj.titles[key],
                content: {
                    value: obj[key] && obj[key].toString(),
                },
            })),
            toolbar: select && selectedIds && (
                <button onClick={() => select(obj.id)}>
                    {selectedIds.includes(obj.id) ? (
                        <FaRegCircleCheck />
                    ) : (
                        <FaRegCircle />
                    )}
                </button>
            ),
        });
    }

    static createCardListData(statusEffects, { select, selectedIds } = {}) {
        return statusEffects.map((dictObj) =>
            this.createCardData(dictObj, { select, selectedIds })
        );
    }
}
