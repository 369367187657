import { useContext, useState } from 'react';

import TextInput from '../../common/text_input/TextInput';
import SkillUtils from '../../../utils/SkillUtils';
import CardMasonry from '../../common/card_masonry/CardMasonry';
import StateContext from '../../../providers/StateProvider';
import StateConstants from '../../../constants/StateConstants';
import { useNavigate } from 'react-router-dom';

export default function SkillList() {
    // Navigation
    const navigate = useNavigate();

    // States
    const { state } = useContext(StateContext);
    const commonStates = StateConstants.CommonStates;
    const [searchValue, setSearchValue] = useState('');

    return (
        <div className="flex_column full_width padding">
            <div className="flex_row full_width" style={{ flexWrap: 'wrap' }}>
                <div
                    style={{
                        flex: 1,
                        minWidth: '200px',
                        maxWidth: '400px',
                    }}
                >
                    <TextInput
                        label="Search Filter"
                        onChange={setSearchValue}
                    />
                </div>
            </div>
            <CardMasonry
                loading={state[commonStates.LOADING_SKILLS]}
                data={SkillUtils.createCardListData(
                    state[commonStates.SKILLS],
                    {
                        searchValue: searchValue,
                        edit: (skillId) => {
                            navigate(`/skills/${skillId}/edit`);
                        },
                    }
                )}
                columnWidth={200}
            />
        </div>
    );
}
