class StateConstants {
    static CommonStates = {
        ASSETS: 'assets',
        LOADING_ASSETS: 'loadingAssets',
        TYPE_CLASSES: 'typeClasses',
        LOADING_TYPE_CLASSES: 'loadingTypeClasses',
        SKILLS: 'skills',
        LOADING_SKILLS: 'loadingSkills',
        MODIFIERS: 'modifiers',
        LOADING_MODIFIERS: 'loadingModifiers',
        ENTITIES: 'entities',
        LOADING_ENTITIES: 'loadingEntities',
        LEVELS: 'levels',
        LOADING_LEVELS: 'loadingLevels',
        STATUS_EFFECTS: 'statusEffects',
        LOADING_STATUS_EFFECTS: 'loadingStatusEffects',
        TIERS: 'tiers',
        LOADING_TIERS: 'loadingTiers',
    };

    static EnumStates = {
        ASSET_TYPES: 'assetTypes',
        ATTACK_TYPES: 'attackTypes',
        GAME_MODE_TYPES: 'gameModeTypes',
        MODIFIER_TYPES: 'modifierTypes',
        STATUS_EFFECT_TYPES: 'statusEffectTypes',
        USER_ROLES: 'userRoles',
        LOADING_ENUMS: 'loadingEnums',
    };

    static AssetStates = {
        TYPE: 'assetFormType',
        REFERENCE: 'assetFormReference',
        IMAGE: 'assetFormImage',
    };

    static TypeClassStates = {
        NAME: 'typeClassFormName',
        DESC_KEY: 'typeClassFormDescriptionKey',
        COLOUR: 'typeClassFormColour',
        ASSET_IDS: 'typeClassFormAssetIds',
    };

    static SkillStates = {
        NAME: 'skillFormName',
        DESC_KEY: 'skillFormDescriptionKey',
        DAMAGE: 'skillFormDamage',
        ATTACK_SPEED: 'skillFormAttackSpeed',
        ATTACK_TYPE: 'skillFormAttackType',
        PROJ_SPEED: 'skillFormProjectileSpeed',
        PROJ_ANGLE: 'skillFormProjectileAngle',
        RANGE: 'skillFormRange',
        PRICE: 'skillFormPrice',
        COLOUR: 'skillFormColour',
        DAMAGE_TYPE_ID: 'skillFormDamageTypeId',
        TIER_ID: 'skillFormTierId',
        ASSET_IDS: 'skillFormAssetIds',
        MODIFIER_IDS: 'skillFormModifierIds',
        STATUS_EFFECT_IDS: 'skillFormStatusEffectIds',
    };

    static ModifierStates = {
        NAME: 'modifierFormName',
        DESC_KEY: 'modifierFormDescriptionKey',
        TYPE: 'modifierFormProjectileSpeed',
        EXPIRATION: 'modifierFormRange',
        VALUE: 'modifierFormPrice',
        PRICE: 'modifierFormModifierIds',
        GROUPED_ASSET_IDS: 'modifierFormGroupedAssetIds',
    };

    static EntityStates = {
        NAME: 'entityFormName',
        DESC_KEY: 'entityFormDescriptionKey',
        PRICE: 'entityFormPrice',
        TEAM_ID: 'entityFormTeamId',
        SIZE_SCALE: 'entityFormSizeScale',
        HEALTH_POINTS: 'entityFormHealthPoints',
        MOVEMENT_SPEED: 'entityFormMovementSpeed',
        BASE_HP_REGEN: 'entityFormBaseHPRegen',
        ARMOUR: 'entityFormArmour',
        ARMOUR_TYPE_ID: 'entityFormArmourTypeId',
        BASE_ATTACK_TYPE: 'entityFormBaseAttackType',
        BASE_ATTACK_RANGE: 'entityFormBaseAttackRange',
        BASE_ATTACK_SPEED: 'entityFormBaseAttackSpeed',
        BASE_ATTACK_PROJECTILE_SPEED: 'entityFormBaseAttackProjectileSpeed',
        BASE_ATTACK_DAMAGE: 'entityFormBaseAttackDamage',
        BASE_ATTACK_DAMAGE_TYPE_ID: 'entityFormBaseAttackDamageTypeId',
        SKILL_IDS: 'entityFormSkillIds',
        MODIFIER_IDS: 'entityFormModifierIds',
        GROUPED_ASSET_IDS: 'entityFormGroupedAssetIds',
    };

    static LevelStates = {
        NAME: 'levelFormName',
        DESC_KEY: 'levelFormDescriptionKey',
        GAME_MODE: 'levelFormGameMode',
        PRIORITY: 'levelFormPriority',
        INITIAL_GOLD: 'levelFormInitialGold',
        INITIAL_INCOME: 'levelFormInitialIncome',
        INITIAL_HP: 'levelFormInitialHP',
        INITIAL_HP_REGEN: 'levelFormInitialHPRegen',
        INCOME_INCREASE_PER_SECOND: 'levelFormIncomeIncreasePerSecond',
        INCOME_INCREASE_AMOUNT: 'levelFormIncomeIncreaseAmount',
        SHOP: 'levelFormShop',
        MODIFIER_IDS: 'levelFormModifierIds',
        MODIFIER_GROUP_POOL: 'levelFormModifierGroupPool',
        MODIFIER_POOL: 'levelFormModifierPool',
        SKILL_POOL: 'levelFormSkillPool',
        SHOWCASE_ASSET_IDS: 'levelFormGroupedShowcaseAssetIds',
        WAVES: 'levelFormWaves',
    };

    static WaveStates = {
        NAME: 'waveFormName',
        DESC_KEY: 'waveFormDescriptionKey',
        PRIORITY: 'waveFormPriority',
        DURATION: 'waveFormDuration',
        MODIFIER_IDS: 'waveFormModifierIds',
        MODIFIER_GROUP_IDS: 'waveFormModifierGroupIds',
        WAVE_ENTITIES: 'waveFormWaveEntities',
    };

    static ShopStates = {
        RESET_TIME_IN_SECONDS: 'levelFormShopResetTimeInSeconds',
        GOLD_INCREASE_PER_REROLL: 'levelFormShopGoldIncreasePerReRoll',
        INITIAL_REROLL_COUNT: 'levelFormShopInitialReRollCount',
        INITIAL_REROLL_COST: 'levelFormShopInitialReRollCost',
        INITIAL_FREE_REROLLS: 'levelFormShopInitialFreeReRolls',
        REROLL_COUNT_INCREASE_TIME_IN_SECONDS:
            'levelFormShopReRollCountIncreaseTimeInSeconds',
        REROLL_COUNT_INCREASE_AMOUNT: 'levelFormShopReRollCountIncreaseAmount',
        REROLL_COST_INCREASE_PER_REROLL:
            'levelFormShopReRollCostIncreasePerReRoll',
    };
}

export default StateConstants;
