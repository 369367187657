import { useContext, useEffect, useState } from 'react';

import SkillList from './SkillList';
import ErrorUtils from '../../../utils/ErrorUtils';
import TierService from '../../../services/TierService';
import AssetService from '../../../services/AssetService';
import SkillService from '../../../services/SkillService';
import StateContext from '../../../providers/StateProvider';
import StateConstants from '../../../constants/StateConstants';
import SkillFormModal from './skill_form_modal/SkillFormModal';
import ModifierService from '../../../services/ModifierService';
import TypeClassService from '../../../services/TypeClassService';
import StatusEffectService from '../../../services/StatusEffectService';
import NotificationContext from '../../../providers/NotificationProvider';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export default function SkillPage() {
    // Navigation
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    // Notification
    const { raiseNotification } = useContext(NotificationContext);

    // States
    const { state, setState } = useContext(StateContext);
    const commonStates = StateConstants.CommonStates;
    const enumStates = StateConstants.EnumStates;
    const [formModalMode, setFormModalMode] = useState(undefined);

    // Functions
    const getSkills = async () => {
        setState(commonStates.LOADING_SKILLS, true);
        await SkillService.getAll()
            .then((response) => {
                setState(commonStates.SKILLS, response.data);
            })
            .catch((error) => {
                raiseNotification(ErrorUtils.getErrorMessage(error), {
                    type: 'error',
                });
            });
        setState(commonStates.LOADING_SKILLS, false);
    };

    const getTypeClasses = async () => {
        setState(commonStates.LOADING_TYPE_CLASSES, true);
        await TypeClassService.getAllTypeClasses()
            .then((response) =>
                setState(commonStates.TYPE_CLASSES, response.data)
            )
            .catch((error) =>
                raiseNotification(ErrorUtils.getErrorMessage(error), {
                    type: 'error',
                })
            );
        setState(commonStates.LOADING_TYPE_CLASSES, false);
    };

    const getStatusEffects = async () => {
        setState(commonStates.LOADING_STATUS_EFFECTS, true);
        await StatusEffectService.getAllStatusEffects()
            .then((response) =>
                setState(commonStates.STATUS_EFFECTS, response.data)
            )
            .catch((error) =>
                raiseNotification(ErrorUtils.getErrorMessage(error), {
                    type: 'error',
                })
            );
        setState(commonStates.LOADING_STATUS_EFFECTS, false);
    };

    const getTiers = async () => {
        setState(commonStates.LOADING_TIERS, true);
        await TierService.getAll()
            .then((response) => setState(commonStates.TIERS, response.data))
            .catch((error) =>
                raiseNotification(ErrorUtils.getErrorMessage(error), {
                    type: 'error',
                })
            );
        setState(commonStates.LOADING_TIERS, false);
    };

    const getAssets = async () => {
        setState(commonStates.LOADING_ASSETS, true);
        await AssetService.getAllAssets()
            .then((response) => setState(commonStates.ASSETS, response.data))
            .catch((error) =>
                raiseNotification(ErrorUtils.getErrorMessage(error), {
                    type: 'error',
                })
            );
        setState(commonStates.LOADING_ASSETS, false);
    };

    const getModifiers = async () => {
        setState(commonStates.LOADING_MODIFIERS, true);
        await ModifierService.getAllModifiers()
            .then((response) => setState(commonStates.MODIFIERS, response.data))
            .catch((error) =>
                raiseNotification(ErrorUtils.getErrorMessage(error), {
                    type: 'error',
                })
            );
        setState(commonStates.LOADING_MODIFIERS, false);
    };

    // Startup
    useEffect(() => {
        getStatusEffects();
        getModifiers();
        getSkills();
        getTypeClasses();
        getTiers();
        getAssets();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setFormModalMode(
            location.pathname === '/skills/create'
                ? 'create'
                : params.skillId &&
                  location.pathname === `/skills/${params.skillId}/edit`
                ? 'edit'
                : undefined
        );
        // eslint-disable-next-line
    }, [location.pathname]);

    return (
        <div className="page scroll_more">
            <div
                className="flex_row full_width padding border_bottom"
                style={{
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <h1>Skills</h1>
                <div className="flex_row" style={{ flexWrap: 'wrap' }}>
                    <button
                        className="solid"
                        onClick={() => navigate('/skills/create')}
                        disabled={state[enumStates.LOADING_ENUMS]}
                    >
                        Create New
                    </button>
                </div>
            </div>
            <SkillList />
            <SkillFormModal
                mode={
                    location.pathname === '/skills/create'
                        ? 'create'
                        : params.skillId &&
                          location.pathname === `/skills/${params.skillId}/edit`
                        ? 'edit'
                        : undefined
                }
                visible={Boolean(formModalMode)}
                setVisible={(value) => {
                    if (!value) {
                        setFormModalMode(undefined);
                    }
                }}
                getSkills={getSkills}
            />
        </div>
    );
}
