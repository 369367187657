import axios from 'axios';

import AuthService from './AuthService';

export default class StatusEffectService {
    static async createStatusEffect(data) {
        await AuthService.refreshAccessTokenIfExpired();
        const response = await axios.post('/statusEffects', data);
        return response;
    }

    static async getAllStatusEffects() {
        await AuthService.refreshAccessTokenIfExpired();
        const response = await axios.get('/statusEffects');
        return response;
    }
}
