import { FaPen } from 'react-icons/fa6';

import CardModel from '../models/component_models/CardModel';
import DataUtils from './DataUtils';
import TierUtils from './TierUtils';
import AssetUtils from './AssetUtils';
import AssetModel from '../models/data_models/AssetModel';
import SkillModel from '../models/data_models/SkillModel';
import TreeViewModel from '../models/component_models/TreeViewModel';
import TypeClassUtils from './TypeClassUtils';
import StatusEffectUtils from './StatusEffectUtils';
import GalleryImageModel from '../models/component_models/GalleryImageModel';
import {
    GalleryPillLabel,
    GalleryImageBorder,
} from '../components/common/gallery_overlays/GalleryOverlays';

class SkillUtils {
    static filterSkills(skills, searchValue) {
        const filterKeys = ['id', 'name', 'descriptionKey'];
        const regex = new RegExp(searchValue.toLowerCase());

        return skills.filter((skill) => {
            const searchValues = Object.entries(skill)
                .filter(([key]) => filterKeys.includes(key))
                .map(([_, value]) => value);

            const isAnyValueMatch = searchValues
                .map((value) =>
                    regex.test((value ?? '').toString().toLowerCase())
                )
                .some(Boolean);

            return isAnyValueMatch;
        });
    }

    static sortByTypeClass(skills) {
        return skills.sort(
            (prev, next) => prev.typeClass?.id - next.typeClass?.id
        );
    }

    static sortByTier(skills) {
        return skills.sort((prev, next) => prev.tier?.id - next.tier?.id);
    }

    static createGalleryImageData(dictObj) {
        const obj = new SkillModel(dictObj);
        const sortedAssets = AssetUtils.sortByAssetType(obj.assets);
        const shownAsset =
            sortedAssets && sortedAssets[0]
                ? new AssetModel(sortedAssets[0])
                : {};
        return new GalleryImageModel({
            id: obj.id,
            alt: shownAsset.assetReference,
            src: shownAsset.imageUrl,
            tooltip: obj.primitiveKeys.map((key) => ({
                title: obj.titles[key],
                value: obj[key],
            })),
            overlays: [
                <GalleryImageBorder color={obj.tier?.colour} />,
                <GalleryPillLabel text={dictObj.count} circular top />,
                <GalleryPillLabel text={obj.name} color={obj.colour} />,
            ],
        });
    }

    static createGalleryData(dictObjs) {
        const sortedByTier = this.sortByTier(dictObjs);
        const sortedByTypeClass = this.sortByTypeClass(sortedByTier);
        return sortedByTypeClass.map((dictObj) =>
            this.createGalleryImageData(dictObj)
        );
    }

    static createCardData(dictObj, { edit }) {
        const obj = new SkillModel(dictObj);
        return new CardModel({
            id: obj.id,
            title: obj.name,
            color: obj.colour,
            borderColor: obj.tier?.colour,
            label: dictObj.count,
            width: 300,
            sections: [
                {
                    type: 'gallery',
                    title: obj.titles.assets,
                    content: {
                        data: AssetUtils.createGalleryData(obj.assets),
                        highlight: true,
                    },
                },
                ...[
                    'descriptionKey',
                    'damage',
                    'attackSpeed',
                    'attackType',
                    'projectileSpeed',
                    'projectileAngle',
                    'range',
                    'price',
                    'colour',
                ].map((key) => ({
                    type: 'value',
                    title: obj.titles[key],
                    content: {
                        value: obj[key] && obj[key].toString(),
                    },
                })),
            ],
            toolbar: edit && (
                <button
                    onClick={() => {
                        edit(obj.id);
                    }}
                >
                    <FaPen />
                </button>
            ),
        });
    }

    static createCardListData(skills, { searchValue, edit } = {}) {
        const filteredSkills = searchValue
            ? this.filterSkills(skills, searchValue)
            : skills;
        return filteredSkills.map((dictItem) =>
            this.createCardData(dictItem, { edit: edit })
        );
    }

    static createTreeViewNodes(dictObj) {
        const obj = new SkillModel(dictObj);
        return [
            {
                title: obj.titles.damageType,
                type: 'card',
                content: TypeClassUtils.createCardData(obj.damageType),
            },
            {
                title: obj.titles.tier,
                type: 'card',
                content: TierUtils.createCardData(obj.tier),
            },
            {
                title: obj.titles.statusEffects,
                type: 'cardMasonry',
                content: {
                    data: obj.statusEffects,
                    mapper: StatusEffectUtils,
                    columnWidth: 200,
                },
            },
            {
                title: obj.titles.modifiers,
            },
        ];
    }

    static createTreeViewData(dictItem) {
        const cardData = this.createCardData(dictItem);
        return new TreeViewModel({
            root: { type: 'card', content: cardData },
            nodes: this.createTreeViewNodes(dictItem),
        });
    }

    // TODO: Delete below functions
    static createSkillDTO(
        skillName,
        skillDescriptionKey,
        skillProjectileSpeed,
        skillRange,
        skillPrice,
        skillModifierIds,
        skillAttackSpeed,
        skillAttackType,
        skillDamage,
        skillDamageTypeId,
        skillAssetIds
    ) {
        return {
            name: DataUtils.setString(skillName),
            descriptionKey: DataUtils.setString(skillDescriptionKey),
            projectileSpeed: DataUtils.setFloat(skillProjectileSpeed),
            range: DataUtils.setFloat(skillRange),
            price: DataUtils.setFloat(skillPrice),
            modifierIds: skillModifierIds,
            assetIds: skillAssetIds,
            attackSpeed: DataUtils.setFloat(skillAttackSpeed),
            attackType: DataUtils.setString(skillAttackType),
            damage: DataUtils.setFloat(skillDamage),
            damageTypeId: DataUtils.setInt(skillDamageTypeId),
        };
    }

    static createSkillLabelData(skill) {
        return [
            { title: 'ID', value: skill.id },
            { title: 'Skill Name', value: skill.name },
            { title: 'Desc. Key', value: skill.descriptionKey },
            { title: 'Damage', value: skill.damage },
            { title: 'Attack Speed', value: skill.attackSpeed },
            { title: 'Attack Type', value: skill.attackType },
            { title: 'Projectile Speed', value: skill.projectileSpeed },
            { title: 'Range', value: skill.range },
            { title: 'Price', value: skill.price },
            { title: 'Created At', value: skill.createdAt },
        ];
    }
}

export default SkillUtils;
