import React, { useContext, useEffect, useState } from 'react';

import Modal from '../../../common/modal/Modal';
import styles from './SkillFormModal.module.css';
import Drawer from '../../../common/drawer/Drawer';
import Gallery from '../../../common/gallery/Gallery';
import Dropdown from '../../../common/dropdown/Dropdown';
import EnumUtils from '../../../../utils/EnumUtils';
import TierUtils from '../../../../utils/TierUtils';
import TextInput from '../../../common/text_input/TextInput';
import ErrorUtils from '../../../../utils/ErrorUtils';
import AssetUtils from '../../../../utils/AssetUtils';
import ColorInput from '../../../common/color_input/ColorInput';
import SkillModel from '../../../../models/data_models/SkillModel';
import CardMasonry from '../../../common/card_masonry/CardMasonry';
import SkillService from '../../../../services/SkillService';
import StateContext from '../../../../providers/StateProvider';
import ModifierUtils from '../../../../utils/ModifierUtils';
import TypeClassUtils from '../../../../utils/TypeClassUtils';
import StateConstants from '../../../../constants/StateConstants';
import StatusEffectUtils from '../../../../utils/StatusEffectUtils';
import NotificationContext from '../../../../providers/NotificationProvider';
import { useNavigate, useParams } from 'react-router-dom';

export default function SkillFormModal(props) {
    // Navigation
    const params = useParams();
    const navigate = useNavigate();

    // Notification
    const { raiseNotification, removeNotification } =
        useContext(NotificationContext);

    // States
    const { state, setState } = useContext(StateContext);
    const skillStates = StateConstants.SkillStates;
    const commonStates = StateConstants.CommonStates;
    const enumStates = StateConstants.EnumStates;
    const [assetSearchValue, setAssetSearchValue] = useState('');
    const [damageTypeSearchValue, setDamageTypeSearchValue] = useState('');

    // Startup
    useEffect(() => {
        if (props.mode === 'create') {
            setState({
                [skillStates.NAME]: '',
                [skillStates.DESC_KEY]: '',
                [skillStates.DAMAGE]: '',
                [skillStates.ATTACK_SPEED]: '',
                [skillStates.ATTACK_TYPE]: state[enumStates.ATTACK_TYPES][0],
                [skillStates.PROJ_SPEED]: '',
                [skillStates.PROJ_ANGLE]: '',
                [skillStates.RANGE]: '',
                [skillStates.PRICE]: '',
                [skillStates.COLOUR]: '',
                [skillStates.DAMAGE_TYPE_ID]: undefined,
                [skillStates.TIER_ID]: undefined,
                [skillStates.ASSET_IDS]: [],
                [skillStates.MODIFIER_IDS]: [],
                [skillStates.STATUS_EFFECT_IDS]: [],
            });
        } else if (
            props.mode === 'edit' &&
            !state[commonStates.LOADING_SKILLS]
        ) {
            const skillDict = state[commonStates.SKILLS].find(
                (skill) => skill.id.toString() === params.skillId
            );
            if (skillDict) {
                setState({
                    [skillStates.NAME]: skillDict.name,
                    [skillStates.DESC_KEY]: skillDict.descriptionKey,
                    [skillStates.DAMAGE]: skillDict.damage,
                    [skillStates.ATTACK_SPEED]: skillDict.attackSpeed,
                    [skillStates.ATTACK_TYPE]: skillDict.attackType,
                    [skillStates.PROJ_SPEED]: skillDict.projectileSpeed,
                    [skillStates.PROJ_ANGLE]: skillDict.projectileAngle,
                    [skillStates.RANGE]: skillDict.range,
                    [skillStates.PRICE]: skillDict.price,
                    [skillStates.COLOUR]: skillDict.colour,
                    [skillStates.DAMAGE_TYPE_ID]: skillDict.damageType?.id,
                    [skillStates.TIER_ID]: skillDict.tier?.id,
                    [skillStates.ASSET_IDS]: (skillDict.assets ?? []).map(
                        (asset) => asset.id
                    ),
                    [skillStates.MODIFIER_IDS]: (skillDict.modifiers ?? []).map(
                        (modifier) => modifier.id
                    ),
                    [skillStates.STATUS_EFFECT_IDS]: (
                        skillDict.statusEffects ?? []
                    ).map((statusEffect) => statusEffect.id),
                });
            }
        }
        // eslint-disable-next-line
    }, [props.mode, state[commonStates.LOADING_SKILLS]]);

    const createSkill = async () => {
        const notificationId = raiseNotification(`Creating skill...`, {
            permanent: true,
        });
        const data = SkillModel.createDTO({
            name: state[skillStates.NAME],
            descriptionKey: state[skillStates.DESC_KEY],
            damage: state[skillStates.DAMAGE],
            attackSpeed: state[skillStates.ATTACK_SPEED],
            attackType: state[skillStates.ATTACK_TYPE],
            projectileSpeed: state[skillStates.PROJ_SPEED],
            projectileAngle: state[skillStates.PROJ_ANGLE],
            range: state[skillStates.RANGE],
            price: state[skillStates.PRICE],
            colour: state[skillStates.COLOUR],
            damageTypeId: state[skillStates.DAMAGE_TYPE_ID],
            tierId: state[skillStates.TIER_ID],
            assetIds: state[skillStates.ASSET_IDS],
            modifierIds: state[skillStates.MODIFIER_IDS],
            statusEffectIds: state[skillStates.STATUS_EFFECT_IDS],
        });
        SkillService.create(data)
            .then((response) => {
                removeNotification(notificationId);
                raiseNotification(
                    `Skill was created. ID: ${response.data['id']}`,
                    { type: 'success' }
                );
                props.getSkills();
            })
            .catch((error) => {
                removeNotification(notificationId);
                raiseNotification(ErrorUtils.getErrorMessage(error), {
                    type: 'error',
                });
            });
    };

    const updateSkill = async () => {
        const notificationId = raiseNotification(`Updating skill...`, {
            permanent: true,
        });
        const data = SkillModel.createDTO({
            name: state[skillStates.NAME],
            descriptionKey: state[skillStates.DESC_KEY],
            damage: state[skillStates.DAMAGE],
            attackSpeed: state[skillStates.ATTACK_SPEED],
            attackType: state[skillStates.ATTACK_TYPE],
            projectileSpeed: state[skillStates.PROJ_SPEED],
            projectileAngle: state[skillStates.PROJ_ANGLE],
            range: state[skillStates.RANGE],
            price: state[skillStates.PRICE],
            colour: state[skillStates.COLOUR],
            damageTypeId: state[skillStates.DAMAGE_TYPE_ID],
            tierId: state[skillStates.TIER_ID],
            assetIds: state[skillStates.ASSET_IDS],
            modifierIds: state[skillStates.MODIFIER_IDS],
            statusEffectIds: state[skillStates.STATUS_EFFECT_IDS],
        });
        SkillService.update(params.skillId, data)
            .then((response) => {
                removeNotification(notificationId);
                raiseNotification(
                    `Skill was updated. ID: ${response.data['id']}`,
                    { type: 'success' }
                );
                props.getSkills();
                navigate(`/skills`);
            })
            .catch((error) => {
                removeNotification(notificationId);
                raiseNotification(ErrorUtils.getErrorMessage(error), {
                    type: 'error',
                });
            });
    };

    return (
        <Modal
            title={props.mode === 'edit' ? 'Edit Skill' : 'Create Skill'}
            visible={props.visible}
            setVisible={props.setVisible}
            onClose={() => {
                navigate('/skills');
            }}
        >
            <div className={styles.form_modal}>
                <TextInput
                    label={`${SkillModel.titles.name} (String)`}
                    value={state[skillStates.NAME]}
                    onChange={(value) => setState(skillStates.NAME, value)}
                />
                <TextInput
                    label={`${SkillModel.titles.descriptionKey} (String)`}
                    value={state[skillStates.DESC_KEY]}
                    onChange={(value) => setState(skillStates.DESC_KEY, value)}
                />
                <TextInput
                    type="float"
                    label={`${SkillModel.titles.damage} (Float)`}
                    value={state[skillStates.DAMAGE]}
                    onChange={(value) => setState(skillStates.DAMAGE, value)}
                />
                <TextInput
                    type="float"
                    label={`${SkillModel.titles.attackSpeed} (Float)`}
                    value={state[skillStates.ATTACK_SPEED]}
                    onChange={(value) =>
                        setState(skillStates.ATTACK_SPEED, value)
                    }
                />
                <Dropdown
                    label={SkillModel.titles.attackType}
                    onChange={(value) =>
                        setState(skillStates.ATTACK_TYPE, value)
                    }
                    value={state[skillStates.ATTACK_TYPE]}
                >
                    {EnumUtils.createTitleMapping(
                        state[enumStates.ATTACK_TYPES]
                    ).map(({ title, value }, index) => (
                        <option key={index} value={value}>
                            {title}
                        </option>
                    ))}
                </Dropdown>
                <TextInput
                    type="float"
                    label={`${SkillModel.titles.projectileSpeed} (Float)`}
                    value={state[skillStates.PROJ_SPEED]}
                    onChange={(value) =>
                        setState(skillStates.PROJ_SPEED, value)
                    }
                />
                <TextInput
                    type="float"
                    label={`${SkillModel.titles.projectileAngle} (Float)`}
                    value={state[skillStates.PROJ_ANGLE]}
                    onChange={(value) =>
                        setState(skillStates.PROJ_ANGLE, value)
                    }
                />
                <TextInput
                    type="float"
                    label={`${SkillModel.titles.range} (Float)`}
                    value={state[skillStates.RANGE]}
                    onChange={(value) => setState(skillStates.RANGE, value)}
                />
                <TextInput
                    type="float"
                    label={`${SkillModel.titles.price} (Float)`}
                    value={state[skillStates.PRICE]}
                    onChange={(value) => setState(skillStates.PRICE, value)}
                />
                <ColorInput
                    label={SkillModel.titles.colour}
                    value={state[skillStates.COLOUR]}
                    onChange={(value) => setState(skillStates.COLOUR, value)}
                />
                <Drawer
                    label={SkillModel.titles.damageType}
                    text={
                        state[skillStates.DAMAGE_TYPE_ID]
                            ? `Selected. ID: ${
                                  state[skillStates.DAMAGE_TYPE_ID]
                              }`
                            : 'Not selected.'
                    }
                >
                    <div className="flex_column full_width padding">
                        <div
                            className="flex_row full_width"
                            style={{ flexWrap: 'wrap' }}
                        >
                            <div
                                style={{
                                    flex: 1,
                                    minWidth: '200px',
                                    maxWidth: '400px',
                                }}
                            >
                                <TextInput
                                    label="Search Filter"
                                    onChange={setDamageTypeSearchValue}
                                />
                            </div>
                        </div>
                        <Gallery
                            loading={state[commonStates.LOADING_TYPE_CLASSES]}
                            data={TypeClassUtils.createGalleryData(
                                state[commonStates.TYPE_CLASSES],
                                {
                                    searchValue: damageTypeSearchValue,
                                    selectedIds: skillStates.ASSET_IDS,
                                }
                            )}
                            onClick={(id) => {
                                setState(skillStates.DAMAGE_TYPE_ID, id);
                            }}
                            selections={[state[skillStates.DAMAGE_TYPE_ID]]}
                            selectionsFirst
                        />
                    </div>
                </Drawer>
                <Drawer
                    label={SkillModel.titles.tier}
                    text={
                        state[skillStates.TIER_ID]
                            ? `Selected. ID: ${state[skillStates.TIER_ID]}`
                            : 'Not selected.'
                    }
                >
                    <div className="flex_column full_width padding">
                        <CardMasonry
                            loading={state[commonStates.LOADING_TIERS]}
                            columnWidth={120}
                            data={TierUtils.createCardListData(
                                state[commonStates.TIERS],
                                {
                                    select: (id) => {
                                        setState(skillStates.TIER_ID, id);
                                    },
                                    selectedIds: [state[skillStates.TIER_ID]],
                                }
                            )}
                        />
                    </div>
                </Drawer>
                <Drawer
                    label={SkillModel.titles.assets}
                    text={
                        state[skillStates.ASSET_IDS].length > 0
                            ? `Selected ${
                                  state[skillStates.ASSET_IDS].length
                              } asset(s).`
                            : 'Not selected.'
                    }
                >
                    <div className="flex_column full_width padding">
                        <div
                            className="flex_row full_width"
                            style={{ flexWrap: 'wrap' }}
                        >
                            <div
                                style={{
                                    flex: 1,
                                    minWidth: '200px',
                                    maxWidth: '400px',
                                }}
                            >
                                <TextInput
                                    label="Search Filter"
                                    onChange={setAssetSearchValue}
                                />
                            </div>
                        </div>
                        <Gallery
                            loading={state[commonStates.LOADING_ASSETS]}
                            data={AssetUtils.createGalleryData(
                                state[commonStates.ASSETS],
                                {
                                    searchKey: assetSearchValue,
                                    selectedIds: skillStates.ASSET_IDS,
                                }
                            )}
                            onClick={(id) => {
                                if (state[skillStates.ASSET_IDS].includes(id)) {
                                    setState(
                                        skillStates.ASSET_IDS,
                                        state[skillStates.ASSET_IDS].filter(
                                            (assetId) => assetId !== id
                                        )
                                    );
                                } else {
                                    setState(skillStates.ASSET_IDS, [
                                        ...state[skillStates.ASSET_IDS],
                                        id,
                                    ]);
                                }
                            }}
                            selections={state[skillStates.ASSET_IDS]}
                            selectionsFirst
                        />
                    </div>
                </Drawer>
                <Drawer
                    label={SkillModel.titles.modifiers}
                    text={
                        state[skillStates.MODIFIER_IDS].length > 0
                            ? `Selected ${
                                  state[skillStates.MODIFIER_IDS].length
                              } modifier(s).`
                            : 'Not selected.'
                    }
                >
                    <div className="flex_column full_width padding">
                        <CardMasonry
                            loading={state[commonStates.LOADING_MODIFIERS]}
                            columnWidth={120}
                            data={ModifierUtils.createCardListData(
                                state[commonStates.MODIFIERS],
                                {
                                    select: (id) => {
                                        if (
                                            state[
                                                skillStates.MODIFIER_IDS
                                            ].includes(id)
                                        ) {
                                            setState(
                                                skillStates.MODIFIER_IDS,
                                                state[
                                                    skillStates.MODIFIER_IDS
                                                ].filter(
                                                    (modifierId) =>
                                                        modifierId !== id
                                                )
                                            );
                                        } else {
                                            setState(skillStates.MODIFIER_IDS, [
                                                ...state[
                                                    skillStates.MODIFIER_IDS
                                                ],
                                                id,
                                            ]);
                                        }
                                    },
                                    selectedIds:
                                        state[skillStates.MODIFIER_IDS],
                                }
                            )}
                        />
                    </div>
                </Drawer>
                <Drawer
                    label={SkillModel.titles.statusEffects}
                    text={
                        state[skillStates.STATUS_EFFECT_IDS].length > 0
                            ? `Selected ${
                                  state[skillStates.STATUS_EFFECT_IDS].length
                              } status effect(s).`
                            : 'Not selected.'
                    }
                >
                    <div className="flex_column full_width padding">
                        <CardMasonry
                            loading={state[commonStates.LOADING_STATUS_EFFECTS]}
                            columnWidth={120}
                            data={StatusEffectUtils.createCardListData(
                                state[commonStates.STATUS_EFFECTS],
                                {
                                    select: (id) => {
                                        if (
                                            state[
                                                skillStates.STATUS_EFFECT_IDS
                                            ].includes(id)
                                        ) {
                                            setState(
                                                skillStates.STATUS_EFFECT_IDS,
                                                state[
                                                    skillStates
                                                        .STATUS_EFFECT_IDS
                                                ].filter(
                                                    (statusEffectId) =>
                                                        statusEffectId !== id
                                                )
                                            );
                                        } else {
                                            setState(
                                                skillStates.STATUS_EFFECT_IDS,
                                                [
                                                    ...state[
                                                        skillStates
                                                            .STATUS_EFFECT_IDS
                                                    ],
                                                    id,
                                                ]
                                            );
                                        }
                                    },
                                    selectedIds:
                                        state[skillStates.STATUS_EFFECT_IDS],
                                }
                            )}
                        />
                    </div>
                </Drawer>
                <div className="page_row right">
                    <button
                        className="solid"
                        onClick={
                            props.mode === 'create' ? createSkill : updateSkill
                        }
                    >
                        {props.mode === 'create' ? 'Create' : 'Save'}
                    </button>
                </div>
            </div>
        </Modal>
    );
}
