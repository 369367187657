import { useRef } from 'react';

import { FaChevronDown } from 'react-icons/fa';

import styles from './Dropdown.module.css';
import LabelWrapper from '../label_wrapper/LabelWrapper';

function Dropdown(props) {
    const selectRef = useRef();

    const openSelect = () => {
        if (selectRef && selectRef.current) {
            selectRef.current.dispatchEvent(
                new MouseEvent('mousedown', {
                    bubbles: true,
                    cancelable: true,
                    view: window,
                })
            );
        }
    };

    const handleChange = (e) => {
        props.onChange && props.onChange(e.target.value);
    };

    const dropdown = (
        <div className={styles.dropdown} onClick={openSelect}>
            <select
                id={props.id}
                ref={selectRef}
                value={props.value}
                onChange={handleChange}
            >
                {props.children}
            </select>
            <div className={styles.arrow}>
                <FaChevronDown />
            </div>
        </div>
    );

    return props.label ? (
        <LabelWrapper label={props.label}>{dropdown}</LabelWrapper>
    ) : (
        dropdown
    );
}

export default Dropdown;
