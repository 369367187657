import DataUtils from './DataUtils';
import WaveUtils from './WaveUtils';
import CardModel from '../models/component_models/CardModel';
import AssetUtils from './AssetUtils';
import SkillUtils from './SkillUtils';
import LevelModel from '../models/data_models/LevelModel';
import TreeViewModel from '../models/component_models/TreeViewModel';
import ModifierGroupUtils from './ModifierGroupUtils';
import DamageMultiplierRuleUtils from './DamageMultiplierRuleUtils';

class LevelUtils {
    static filterLevels(levels, searchKey) {
        const filterKeys = [
            'name',
            'descriptionKey',
            'gameMode',
            'priority',
            'initialGold',
            'initialIncome',
            'initialHP',
            'initialHPRegen',
            'incomeIncreasePerSeconds',
            'incomeIncreaseAmount',
        ];
        const regex = new RegExp(searchKey.toLowerCase());

        return levels.filter((level) => {
            const searchLevelValues = Object.entries(level)
                .filter(([key]) => filterKeys.includes(key))
                .map(([_, value]) => value);

            const isAnyValueMatch = searchLevelValues
                .map((value) =>
                    regex.test((value ?? '').toString().toLowerCase())
                )
                .some(Boolean);

            return isAnyValueMatch;
        });
    }

    static createCardData(levelDict) {
        const obj = new LevelModel(levelDict);
        return new CardModel({
            id: obj.id,
            title: obj.name,
            sections: [
                {
                    type: 'value',
                    title: obj.titles.id,
                    content: {
                        value: obj.id.toString(),
                    },
                },
                {
                    type: 'gallery',
                    title: 'Assets',
                    content: {
                        data: AssetUtils.createGalleryData(obj.showcaseAssets),
                    },
                },
                ...[
                    'name',
                    'descriptionKey',
                    'gameMode',
                    'priority',
                    'startingGold',
                    'initialPlayerIncome',
                    'initialHP',
                    'initialHPRegen',
                    'incomeIncreasePerSeconds',
                    'incomeIncreaseAmount',
                    'loopMultiplier',
                    'loops',
                ].map((key) => ({
                    type: 'value',
                    title: obj.titles[key],
                    content: {
                        value: obj[key] && obj[key].toString(),
                    },
                })),
            ],
        });
    }

    static createCardListData(levels, { searchKey } = {}) {
        const filteredLevels = searchKey
            ? this.filterLevels(levels, searchKey)
            : levels;
        return filteredLevels.map((levelDict) =>
            this.createCardData(levelDict)
        );
    }

    static createTreeViewNodes(levelDict, navigate = undefined) {
        const level = new LevelModel(levelDict);
        return [
            {
                title: level.titles.shop,
                type: 'card',
                content: new CardModel({
                    width: 300,
                    sections: Object.entries(level.shop.titles).map(
                        ([key, title]) => ({
                            type: 'value',
                            title: title,
                            content: {
                                value:
                                    level.shop[key] &&
                                    level.shop[key].toString(),
                            },
                        })
                    ),
                }),
            },
            {
                title: level.titles.damageMultiplierRules,
                type: 'cardMasonry',
                content: {
                    data: level.damageMultiplierRules.map(
                        (rule, ruleIndex) => ({ id: ruleIndex + 1, ...rule })
                    ),
                    mapper: DamageMultiplierRuleUtils,
                    columnWidth: 150,
                },
            },
            {
                title: level.titles.waves,
                type: 'cardMasonry',
                content: {
                    data: level.waves,
                    mapper: WaveUtils,
                    columnWidth: 100,
                    create: () => {
                        navigate &&
                            navigate(`/levels/${level.id}/waves/create`);
                    },
                    edit: (waveId) => {
                        navigate &&
                            navigate(`/levels/${level.id}/waves/${waveId}`);
                    },
                },
            },
            {
                title: level.titles.pool,
                type: 'treeView',
                content: {
                    root: {
                        type: 'card',
                        content: new CardModel({
                            width: 250,
                            sections: [
                                {
                                    type: 'value',
                                    title: level.pool.titles.name,
                                    content: {
                                        value: level.pool.name,
                                    },
                                },
                            ],
                        }),
                    },
                    nodes: [
                        {
                            title: level.pool.titles.modifierGroupPool,
                            type: 'gallery',
                            content: {
                                data: level.pool.modifierGroupPool.map(
                                    (poolItem) => ({
                                        ...poolItem.modifierGroup,
                                        count: poolItem.count,
                                    })
                                ),
                                mapper: ModifierGroupUtils,
                            },
                        },
                        {
                            title: level.pool.titles.skillPool,
                            type: 'gallery',
                            content: {
                                data: level.pool.skillPool.map((poolItem) => ({
                                    ...poolItem.skill,
                                    count: poolItem.count,
                                })),
                                mapper: SkillUtils,
                            },
                        },
                    ],
                },
            },
        ];
    }

    static createTreeViewData(levelDict, navigate = undefined) {
        const levelCardData = this.createCardData(levelDict);
        return new TreeViewModel({
            root: {
                type: 'card',
                content: levelCardData,
            },
            nodes: this.createTreeViewNodes(levelDict, navigate),
        });
    }

    // TODO: Delete below functions
    static createWaveDTO(
        waveName,
        waveDescriptionKey,
        wavePriority,
        waveDuration,
        waveModifierIds,
        waveEntityIds
    ) {
        return {
            name: DataUtils.setString(waveName),
            descriptionKey: DataUtils.setString(waveDescriptionKey),
            priority: DataUtils.setInt(wavePriority),
            duration: DataUtils.setFloat(waveDuration),
            modifierIds: waveModifierIds,
            entityIds: waveEntityIds,
        };
    }

    static createShopDTO(
        shopResetTimeInSeconds,
        shopGoldIncreasePerReRoll,
        shopInitialReRollCount,
        shopInitialReRollCost,
        shopInitialFreeReRolls,
        shopReRollCountIncreaseTimeInSeconds,
        shopReRollCountIncreaseAmount,
        shopReRollCostIncreasePerReRoll
    ) {
        return {
            resetTimeInSeconds: DataUtils.setFloat(shopResetTimeInSeconds),
            goldIncreasePerReRoll: DataUtils.setFloat(
                shopGoldIncreasePerReRoll
            ),
            initialReRollCount: DataUtils.setInt(shopInitialReRollCount),
            initialReRollCost: DataUtils.setFloat(shopInitialReRollCost),
            initialFreeReRolls: DataUtils.setInt(shopInitialFreeReRolls),
            reRollCountIncreaseTimeInSeconds: DataUtils.setFloat(
                shopReRollCountIncreaseTimeInSeconds
            ),
            reRollCountIncreaseAmount: DataUtils.setInt(
                shopReRollCountIncreaseAmount
            ),
            reRollCostIncreasePerReRoll: DataUtils.setFloat(
                shopReRollCostIncreasePerReRoll
            ),
        };
    }

    static createLevelDTO(
        levelName,
        levelDescriptionKey,
        levelGameMode,
        levelPriority,
        levelStartingGold,
        levelInitialPLayerIncome,
        levelInitialHP,
        levelInitialHPRegen,
        levelIncomeIncreasePerSeconds,
        levelIncomeIncreaseAmount,
        levelModifierIds,
        levelSkillPool,
        levelModifierPool,
        levelShowcaseAssetIds,
        levelShop,
        levelWaves
    ) {
        return {
            name: DataUtils.setString(levelName),
            descriptionKey: DataUtils.setString(levelDescriptionKey),
            gameMode: DataUtils.setString(levelGameMode),
            priority: DataUtils.setInt(levelPriority),
            startingGold: DataUtils.setFloat(levelStartingGold),
            initialPlayerIncome: DataUtils.setFloat(levelInitialPLayerIncome),
            initialHP: DataUtils.setFloat(levelInitialHP),
            initialHPRegen: DataUtils.setFloat(levelInitialHPRegen),
            incomeIncreasePerSeconds: DataUtils.setFloat(
                levelIncomeIncreasePerSeconds
            ),
            incomeIncreaseAmount: DataUtils.setFloat(levelIncomeIncreaseAmount),
            modifierIds: levelModifierIds,
            skillPool: levelSkillPool,
            modifierPool: levelModifierPool,
            showcaseAssetIds: levelShowcaseAssetIds,
            shop: levelShop,
            waves: levelWaves,
        };
    }

    static createShopLabelData(shop) {
        return [
            { title: 'Reset Time In Seconds', value: shop.resetTimeInSeconds },
            {
                title: 'Gold Increase Per Re-Roll',
                value: shop.goldIncreasePerReRoll,
            },
            { title: 'Initial Re-Roll Count', value: shop.initialReRollCount },
            { title: 'Initial Re-Roll Cost', value: shop.initialReRollCost },
            { title: 'Initial Free Re-Rolls', value: shop.initialFreeReRolls },
            {
                title: 'Re-Roll Count Increase Time In Seconds',
                value: shop.reRollCountIncreaseTimeInSeconds,
            },
            {
                title: 'Re-Roll Count Increase Amount',
                value: shop.reRollCountIncreaseAmount,
            },
            {
                title: 'Re-Roll Cost Increase Per Re-Roll',
                value: shop.reRollCostIncreasePerReRoll,
            },
        ];
    }

    static createWaveLabelData(wave) {
        const modifiers =
            wave.modifierIds && wave.modifierIds.length > 0
                ? DataUtils.createDataWithCount([], wave.modifierIds)
                      .map((item) => `[${item.id}]x${item.count}`)
                      .join(', ')
                : '-';
        const entities =
            wave.entityIds && wave.entityIds.length > 0
                ? DataUtils.createDataWithCount([], wave.entityIds)
                      .map((item) => `[${item.id}]x${item.count}`)
                      .join(', ')
                : '-';
        return [
            { title: 'ID', value: wave.id },
            { title: 'Wave Name', value: wave.name },
            { title: 'Desc. Key', value: wave.descriptionKey },
            { title: 'Priority', value: wave.priority },
            { title: 'Duration', value: wave.duration },
            { title: 'Modifiers', value: modifiers },
            { title: 'Entities', value: entities },
        ];
    }
}

export default LevelUtils;
