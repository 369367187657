import { createContext, useReducer } from 'react';

import EnumerationConstants from '../constants/EnumerationConstants';
import StateConstants from '../constants/StateConstants';

const StateContext = createContext();

function StateProvider(props) {
    const commonStates = {
        [StateConstants.CommonStates.ASSETS]: [],
        [StateConstants.CommonStates.LOADING_ASSETS]: true,
        [StateConstants.CommonStates.MODIFIERS]: [],
        [StateConstants.CommonStates.LOADING_MODIFIERS]: true,
        [StateConstants.CommonStates.TYPE_CLASSES]: [],
        [StateConstants.CommonStates.LOADING_TYPE_CLASSES]: true,
        [StateConstants.CommonStates.SKILLS]: [],
        [StateConstants.CommonStates.LOADING_SKILLS]: true,
        [StateConstants.CommonStates.ENTITIES]: [],
        [StateConstants.CommonStates.LOADING_ENTITIES]: true,
        [StateConstants.CommonStates.LEVELS]: [],
        [StateConstants.CommonStates.LOADING_LEVELS]: true,
        [StateConstants.CommonStates.STATUS_EFFECTS]: [],
        [StateConstants.CommonStates.LOADING_STATUS_EFFECTS]: true,
        [StateConstants.CommonStates.TIERS]: [],
        [StateConstants.CommonStates.LOADING_TIERS]: true,
    };

    const enumStates = {
        [StateConstants.EnumStates.ASSET_TYPES]: [],
        [StateConstants.EnumStates.ATTACK_TYPES]: [],
        [StateConstants.EnumStates.GAME_MODE_TYPES]: [],
        [StateConstants.EnumStates.MODIFIER_TYPES]: [],
        [StateConstants.EnumStates.STATUS_EFFECT_TYPES]: [],
        [StateConstants.EnumStates.USER_ROLES]: [],
        [StateConstants.EnumStates.LOADING_ENUMS]: true,
    };

    const assetFormStates = {
        [StateConstants.AssetStates.TYPE]: '',
        [StateConstants.AssetStates.REFERENCE]: '',
        [StateConstants.AssetStates.IMAGE]: undefined,
    };

    const typeClassFormStates = {
        [StateConstants.TypeClassStates.NAME]: '',
        [StateConstants.TypeClassStates.DESC_KEY]: '',
        [StateConstants.TypeClassStates.COLOUR]: '',
        [StateConstants.TypeClassStates.ASSET_IDS]: [],
    };

    const skillFormStates = {
        [StateConstants.SkillStates.NAME]: '',
        [StateConstants.SkillStates.DESC_KEY]: '',
        [StateConstants.SkillStates.DAMAGE]: '',
        [StateConstants.SkillStates.ATTACK_SPEED]: '',
        [StateConstants.SkillStates.ATTACK_TYPE]: '',
        [StateConstants.SkillStates.PROJ_SPEED]: '',
        [StateConstants.SkillStates.PROJ_ANGLE]: '',
        [StateConstants.SkillStates.RANGE]: '',
        [StateConstants.SkillStates.PRICE]: '',
        [StateConstants.SkillStates.COLOUR]: '',
        [StateConstants.SkillStates.DAMAGE_TYPE_ID]: undefined,
        [StateConstants.SkillStates.TIER_ID]: undefined,
        [StateConstants.SkillStates.ASSET_IDS]: [],
        [StateConstants.SkillStates.MODIFIER_IDS]: [],
        [StateConstants.SkillStates.STATUS_EFFECT_IDS]: [],
    };

    const modifierFormStates = {
        [StateConstants.ModifierStates.NAME]: '',
        [StateConstants.ModifierStates.DESC_KEY]: '',
        [StateConstants.ModifierStates.TYPE]: '',
        [StateConstants.ModifierStates.EXPIRATION]: '',
        [StateConstants.ModifierStates.VALUE]: '',
        [StateConstants.ModifierStates.PRICE]: '',
        [StateConstants.ModifierStates.GROUPED_ASSET_IDS]: Object.fromEntries(
            Object.keys(EnumerationConstants.AssetTypes).map((assetType) => [
                assetType,
                [],
            ])
        ),
    };

    const entityFormStates = {
        [StateConstants.EntityStates.NAME]: '',
        [StateConstants.EntityStates.DESC_KEY]: '',
        [StateConstants.EntityStates.PRICE]: '',
        [StateConstants.EntityStates.TEAM_ID]: '',
        [StateConstants.EntityStates.SIZE_SCALE]: '',
        [StateConstants.EntityStates.HEALTH_POINTS]: '',
        [StateConstants.EntityStates.MOVEMENT_SPEED]: '',
        [StateConstants.EntityStates.BASE_HP_REGEN]: '',
        [StateConstants.EntityStates.ARMOUR]: '',
        [StateConstants.EntityStates.ARMOUR_TYPE_ID]: undefined,
        [StateConstants.EntityStates.BASE_ATTACK_TYPE]: '',
        [StateConstants.EntityStates.BASE_ATTACK_RANGE]: '',
        [StateConstants.EntityStates.BASE_ATTACK_SPEED]: '',
        [StateConstants.EntityStates.BASE_ATTACK_PROJECTILE_SPEED]: '',
        [StateConstants.EntityStates.BASE_ATTACK_DAMAGE]: '',
        [StateConstants.EntityStates.BASE_ATTACK_DAMAGE_TYPE_ID]: undefined,
        [StateConstants.EntityStates.SKILL_IDS]: [],
        [StateConstants.EntityStates.MODIFIER_IDS]: [],
        [StateConstants.EntityStates.GROUPED_ASSET_IDS]: Object.fromEntries(
            Object.keys(EnumerationConstants.AssetTypes).map((assetType) => [
                assetType,
                [],
            ])
        ),
    };

    const levelFormStates = {
        [StateConstants.LevelStates.NAME]: '',
        [StateConstants.LevelStates.DESC_KEY]: '',
        [StateConstants.LevelStates.GAME_MODE]: '',
        [StateConstants.LevelStates.PRIORITY]: '',
        [StateConstants.LevelStates.INITIAL_GOLD]: '',
        [StateConstants.LevelStates.INITIAL_INCOME]: '',
        [StateConstants.LevelStates.INITIAL_HP]: '',
        [StateConstants.LevelStates.INITIAL_HP_REGEN]: '',
        [StateConstants.LevelStates.INCOME_INCREASE_PER_SECOND]: '',
        [StateConstants.LevelStates.INCOME_INCREASE_AMOUNT]: '',
        [StateConstants.LevelStates.MODIFIER_IDS]: [],
        [StateConstants.LevelStates.SKILL_POOL]: [],
        [StateConstants.LevelStates.MODIFIER_POOL]: [],
        [StateConstants.LevelStates.SHOWCASE_ASSET_IDS]: [],
        [StateConstants.LevelStates.WAVES]: [],
    };

    const levelShopFormStates = {
        [StateConstants.ShopStates.RESET_TIME_IN_SECONDS]: '',
        [StateConstants.ShopStates.GOLD_INCREASE_PER_REROLL]: '',
        [StateConstants.ShopStates.INITIAL_REROLL_COUNT]: '',
        [StateConstants.ShopStates.INITIAL_REROLL_COST]: '',
        [StateConstants.ShopStates.INITIAL_FREE_REROLLS]: '',
        [StateConstants.ShopStates.REROLL_COUNT_INCREASE_TIME_IN_SECONDS]: '',
        [StateConstants.ShopStates.REROLL_COUNT_INCREASE_AMOUNT]: '',
        [StateConstants.ShopStates.REROLL_COST_INCREASE_PER_REROLL]: '',
    };

    const waveFormStates = {
        [StateConstants.WaveStates.NAME]: '',
        [StateConstants.WaveStates.DESC_KEY]: '',
        [StateConstants.WaveStates.PRIORITY]: '',
        [StateConstants.WaveStates.DURATION]: '',
        [StateConstants.WaveStates.MODIFIER_IDS]: [],
        [StateConstants.WaveStates.MODIFIER_GROUP_IDS]: [],
        [StateConstants.WaveStates.WAVE_ENTITIES]: [],
    };

    function reducer(state, action) {
        if (action.type === 'setState') {
            return {
                ...state,
                [action.name]: action.value,
            };
        }
    }

    const [state, dispatch] = useReducer(reducer, {
        ...commonStates,
        ...enumStates,
        ...assetFormStates,
        ...typeClassFormStates,
        ...skillFormStates,
        ...modifierFormStates,
        ...entityFormStates,
        ...levelFormStates,
        ...levelShopFormStates,
        ...waveFormStates,
    });

    const setOneState = (stateName, stateValue) => {
        dispatch({ type: 'setState', name: stateName, value: stateValue });
    };

    const setMultipleStates = (states) => {
        Object.entries(states).forEach(([key, value]) =>
            dispatch({ type: 'setState', name: key, value: value })
        );
    };

    const setState = (...args) => {
        if (args.length === 1) {
            setMultipleStates(args[0]);
        } else if (args.length === 2) {
            setOneState(args[0], args[1]);
        }
    };

    return (
        <StateContext.Provider value={{ state, setState }}>
            {props.children}
        </StateContext.Provider>
    );
}

export { StateProvider };
export default StateContext;
