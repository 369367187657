import axios from 'axios';

import AuthService from './AuthService';

export default class TierService {
    static async getAll() {
        await AuthService.refreshAccessTokenIfExpired();
        const response = await axios.get('/tiers');
        return response;
    }
}
