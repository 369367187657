import { FaRegCircle, FaRegCircleCheck } from 'react-icons/fa6';

import CardModel from '../models/component_models/CardModel';
import TypeClassModel from '../models/data_models/TypeClassModel';

export default class TierUtils {
    static createCardData(dictObj, { select, selectedIds } = {}) {
        const obj = new TypeClassModel(dictObj);
        return new CardModel({
            id: obj.id,
            title: obj.name,
            color: obj.colour,
            width: 200,
            sections: [
                {
                    type: 'value',
                    title: obj.titles.id,
                    content: {
                        value: obj.id.toString(),
                    },
                },
            ],
            toolbar: select && selectedIds && (
                <button onClick={() => select(obj.id)}>
                    {selectedIds.includes(obj.id) ? (
                        <FaRegCircleCheck />
                    ) : (
                        <FaRegCircle />
                    )}
                </button>
            ),
        });
    }

    static createCardListData(tiers, { select, selectedIds } = {}) {
        return tiers.map((dictItem) =>
            this.createCardData(dictItem, { select, selectedIds })
        );
    }
}
