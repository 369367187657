import AssetModel from '../models/data_models/AssetModel';
import StringUtils from './StringUtils';
import GalleryImageModel from '../models/component_models/GalleryImageModel';
import { GalleryPillLabel } from '../components/common/gallery_overlays/GalleryOverlays';

export default class AssetUtils {
    static sortByAssetType(assets) {
        const priorities = {
            icon: 1,
            costume: 2,
        };
        return assets.sort((prevAsset, nextAsset) => {
            const prevPriority = priorities[prevAsset.assetType] ?? Infinity;
            const nextPriority = priorities[nextAsset.assetType] ?? Infinity;

            if (prevPriority !== nextPriority) {
                return prevPriority - nextPriority;
            }

            return prevAsset.assetType.localeCompare(nextAsset.assetType);
        });
    }

    static sortByAssetId(assets) {
        return assets.sort((prev, next) => prev.id - next.id);
    }

    static filterAssets(assets, searchKey, assetType, assetSelections = []) {
        const filterKeys = ['id', 'assetReference'];
        const regex = new RegExp(searchKey.toLowerCase());

        return assets.filter((asset) => {
            if (assetSelections.includes(asset.id)) {
                return true;
            } else {
                const searchAssetValues = Object.entries(asset)
                    .filter(([key]) => filterKeys.includes(key))
                    .map(([_, value]) => value);

                const isAnyValueMatch = searchAssetValues
                    .map((value) =>
                        regex.test((value ?? '').toString().toLowerCase())
                    )
                    .some(Boolean);

                const isTypeMatch =
                    !Boolean(assetType) || asset.assetType === assetType;
                return isAnyValueMatch && isTypeMatch;
            }
        });
    }

    static createGalleryImageData(assetDict) {
        const asset = new AssetModel(assetDict);
        const initials = StringUtils.getInitialsFromCamelCase(asset.assetType);
        const colorMapping = {
            AE: 'crimson',
            CE: 'orangered',
            C: 'orange',
            DE: 'forestgreen',
            EE: 'limegreen',
            I: 'dodgerblue',
            TE: 'navy',
            VE: 'darkmagenta',
            WE: 'hotpink',
        };
        return new GalleryImageModel({
            id: asset.id,
            alt: asset.assetReference,
            src: asset.imageUrl,
            tooltip: asset.getTitleValueMapping(),
            overlays: [
                <GalleryPillLabel
                    color={colorMapping[initials]}
                    text={initials}
                    circular
                />,
            ],
        });
    }

    static createGalleryData(
        assets,
        { searchKey, assetType, selectedIds } = {}
    ) {
        const sortedAssets = this.sortByAssetType(this.sortByAssetId(assets));
        const filteredAssets =
            searchKey || assetType || selectedIds
                ? this.filterAssets(
                      sortedAssets,
                      searchKey,
                      assetType,
                      selectedIds
                  )
                : sortedAssets;
        return filteredAssets.map((asset) =>
            this.createGalleryImageData(asset)
        );
    }

    // TODO: DELETE BELOW FUNCTIONS LATER
    static createImageData(asset) {
        return [
            { title: 'ID', value: asset.id },
            { title: 'Asset Reference', value: asset.assetReference },
            { title: 'Asset Type', value: asset.assetType },
            { title: 'Created At', value: asset.createdAt },
        ];
    }
}
