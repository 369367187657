import axios from 'axios';

import AuthService from './AuthService';

class SkillService {
    static async getAll() {
        await AuthService.refreshAccessTokenIfExpired();
        const response = await axios.get('/skills');
        return response;
    }

    static async create(data) {
        await AuthService.refreshAccessTokenIfExpired();
        const response = await axios.post('/skills', data);
        return response;
    }

    static async update(skillId, data) {
        await AuthService.refreshAccessTokenIfExpired();
        const response = await axios.put(`/skills/${skillId}`, data);
        return response;
    }
}

export default SkillService;
