import { useContext, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import Loading from '../loading_page/Loading';
import LevelList from './LevelList';
import LevelInfo from './LevelInfo';
import ErrorUtils from '../../../utils/ErrorUtils';
import LevelService from '../../../services/LevelService';
import SkillService from '../../../services/SkillService';
import StateContext from '../../../providers/StateProvider';
import EntityService from '../../../services/EntityService';
import StateConstants from '../../../constants/StateConstants';
import ModifierService from '../../../services/ModifierService';
import TypeClassService from '../../../services/TypeClassService';
import NotificationContext from '../../../providers/NotificationProvider';

export default function LevelPage() {
    // Notification
    const { raiseNotification } = useContext(NotificationContext);

    // States
    const { state, setState } = useContext(StateContext);
    const commonStates = StateConstants.CommonStates;

    const getEntities = async () => {
        setState(commonStates.LOADING_ENTITIES, true);
        return await EntityService.getAllEntities()
            .then((response) => {
                setState(commonStates.ENTITIES, response.data);
                return response.data;
            })
            .catch((error) =>
                raiseNotification(ErrorUtils.getErrorMessage(error), {
                    type: 'error',
                })
            )
            .finally(() => {
                setState(commonStates.LOADING_ENTITIES, false);
            });
    };

    const getTypeClasses = async () => {
        setState(commonStates.LOADING_TYPE_CLASSES, true);
        return await TypeClassService.getAllTypeClasses()
            .then((response) => {
                setState(commonStates.TYPE_CLASSES, response.data);
                return response.data;
            })
            .catch((error) =>
                raiseNotification(ErrorUtils.getErrorMessage(error), {
                    type: 'error',
                })
            )
            .finally(() => {
                setState(commonStates.LOADING_TYPE_CLASSES, false);
            });
    };

    const getModifierGroups = async () => {
        setState(commonStates.LOADING_MODIFIERS, true);
        return await ModifierService.getAllModifierGroups()
            .then((response) => {
                setState(commonStates.MODIFIERS, response.data);
                return response.data;
            })
            .catch((error) =>
                raiseNotification(ErrorUtils.getErrorMessage(error), {
                    type: 'error',
                })
            )
            .finally(() => {
                setState(commonStates.LOADING_MODIFIERS, false);
            });
    };

    const getSkills = async () => {
        setState(commonStates.LOADING_SKILLS, true);
        return await SkillService.getAll()
            .then((response) => {
                setState(commonStates.SKILLS, response.data);
                return response.data;
            })
            .catch((error) =>
                raiseNotification(ErrorUtils.getErrorMessage(error), {
                    type: 'error',
                })
            )
            .finally(() => {
                setState(commonStates.LOADING_SKILLS, false);
            });
    };

    const getLevels = async () => {
        setState(commonStates.LOADING_LEVELS, true);
        const typeClasses = await getTypeClasses();
        const entities = await getEntities();
        const modifierGroups = await getModifierGroups();
        const skills = await getSkills();
        await LevelService.getAllLevels()
            .then((response) => {
                const levels = response.data.map((level) => ({
                    ...level,
                    damageMultiplierRules: level.damageMultiplierRules.map(
                        (rule) => {
                            const from = typeClasses.find(
                                (item) => item.id === rule.fromId
                            );
                            const to = typeClasses.find(
                                (item) => item.id === rule.toId
                            );
                            return {
                                multiplier: rule.multiplier,
                                from: from,
                                to: to,
                            };
                        }
                    ),
                    pool: {
                        name: level.pool.name,
                        modifierGroupPool: level.pool.modifierGroupPool.map(
                            (poolItem) => ({
                                count: poolItem.count,
                                modifierGroup: modifierGroups.find(
                                    (item) =>
                                        item.id === poolItem.modifierGroupId
                                ),
                            })
                        ),
                        skillPool: level.pool.skillPool.map((poolItem) => ({
                            count: poolItem.count,
                            skill: skills.find(
                                (item) => item.id === poolItem.skillId
                            ),
                        })),
                    },
                    waves: level.waves.map((wave) => ({
                        ...wave,
                        waveEntities: wave.waveEntities.map((waveEntity) => ({
                            count: waveEntity.count,
                            entity: entities.find(
                                (item) => item.id === waveEntity.entityId
                            ),
                        })),
                    })),
                }));
                setState(commonStates.LEVELS, levels);
            })
            .catch((error) => {
                raiseNotification(ErrorUtils.getErrorMessage(error), {
                    type: 'error',
                });
            });
        setState(commonStates.LOADING_LEVELS, false);
    };

    // Startup
    useEffect(() => {
        getLevels();
        // eslint-disable-next-line
    }, []);

    return state[commonStates.LOADING_LEVELS] ? (
        <Loading />
    ) : (
        <div className="page scroll_more">
            <Routes>
                <Route path="/" element={<LevelList />} />
                <Route
                    path="/:levelId"
                    element={<LevelInfo getLevels={getLevels} />}
                />
                <Route
                    path="/:levelId/waves/create"
                    element={
                        <LevelInfo mode="createWave" getLevels={getLevels} />
                    }
                />
                <Route
                    path="/:levelId/waves/:waveId"
                    element={
                        <LevelInfo mode="editWave" getLevels={getLevels} />
                    }
                />
            </Routes>
        </div>
    );
}
